// Nothing here
.custom-message .MuiOutlinedInput-root {
    border-radius: 0;
}

.custom-message .dropdown {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.custom-message button {
    top: -1px;
    height: 3.56rem;
    border-radius: 0;
    box-shadow: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}